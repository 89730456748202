﻿export class ProductsIndex {
    $el: HTMLDivElement;
    constructor(id: string) {
        this.$el = document.getElementById(id) as HTMLDivElement;

        this.fnInitializeFilterTableSelectMenus();
    }

    fnInitializeFilterTableSelectMenus(): void {
        const lstFilterSelects = Array.from(
            this.$el.querySelectorAll('.js-Products__FilterSelect')
        );

        lstFilterSelects.forEach((value: HTMLUListElement) => {
            const selectForList = document.createElement('select');
            value.parentElement.appendChild(selectForList);

            selectForList.classList.add('c-Products__FilterSelect');

            const lstItems = Array.from(value.querySelectorAll('li'));
            lstItems.forEach((value: HTMLLIElement) => {
                const itemLink = value.querySelector('a');

                const strLinkLocation = itemLink.getAttribute('href');
                const strLinkName = itemLink.innerText;

                const optionForLink = document.createElement('option');
                optionForLink.innerText = strLinkName;
                optionForLink.value = strLinkLocation;

                selectForList.appendChild(optionForLink);
            });

            selectForList.addEventListener('change', (e) => {
                window.location.href = (e.target as HTMLSelectElement).value;
            });
            value.remove();
        });
    }
}
