﻿export default class DistributorForCountry {
    $el: HTMLDivElement;
    $contentContainer: HTMLDivElement;
    constructor($el: HTMLDivElement) {
        this.$el = $el;
        this.fnEnhanceCollapse();
    }

    fnEnhanceCollapse(): void {
        const collapseLink: HTMLAnchorElement = this.$el.querySelector(
            '.js-Distributors__CountryCollapseLink'
        );

        collapseLink.addEventListener('click', (e) => {
            e.preventDefault();

            if (this.$el.classList.contains('-Active')) {
                this.$el.classList.remove('-Active');
            } else {
                this.$el.classList.add('-Active');
            }
        });
    }
}
