﻿export default class SubMenu {
    private $el: HTMLUListElement;

    private $subMenuHeader: HTMLAnchorElement;

    constructor($el: HTMLUListElement) {
        this.$el = $el;
    }
    public init(): void {
        this.$el.setAttribute('aria-expanded', 'false');

        const subMenuItems: HTMLUListElement = this.$el.querySelector(
            '.js-MainMenu__SubItems'
        );
        subMenuItems.classList.add('-Enhanced');
    }
}
