﻿export class Carousel {
    $el: HTMLAnchorElement;

    /*This slider can only be used with two slides ImageSliderTop and ImageSliderBottom.
    Jumbotron used by Exalpha should take the place of the Nordic Carousel in the Future.
    The Carousel is now set to one Slide as top and bottom.
    Last Git hash when there were 2 working slides is d6527270.*/

    constructor() {
        this.$el = document.getElementById('ImageSlider') as HTMLAnchorElement;

        this.fnInitializeCarousel();
    }
    
    fnInitializeCarousel(): void {
        let $this = this;
        window.setTimeout(function () {
            $this.fnFadeToSlide2($this);
        }, 10000);
    }

    fnFadeToSlide2($this: Carousel): void {
        document
            .getElementById('ImageSliderTop')
            .classList.remove('c-Home__Transparent');
        document
            .getElementById('ImageSliderBottom')
            .classList.add('c-Home__Transparent');
        $this.$el.href = 'https://vectorlabs.com/press-releases/vector-laboratories-merges-with-absolute-biotech-significantly-expanding-capabilities-as-a-critical-component-manufacturer-for-life-science-customers';

        window.setTimeout(function () {
            $this.fnFadeToSlide2($this);
        }, 10000);
    }

    
}
