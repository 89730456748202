﻿import DistributorForCountry from './distributorForCountry';

export default class Distributors {
    $el: HTMLDivElement;
    constructor(id: string) {
        this.$el = document.getElementById(id) as HTMLDivElement;

        const lstCountryElements = Array.from(
            this.$el.querySelectorAll('.js-Distributors__Country')
        );

        lstCountryElements.forEach((value: HTMLDivElement) => {
            const country = new DistributorForCountry(value);
        });
    }
}
