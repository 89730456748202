﻿import './mainMenu.scss';
import SubMenu from './subMenu';

export default class MainMenu {
    private $el: HTMLDivElement;

    constructor(id: string) {
        this.$el = document.getElementById(id) as HTMLDivElement;
    }
    public init(): void {
        const $subMenus = this.$el.querySelectorAll('.js-MainMenu__SubMenu');

        $subMenus.forEach((value: Element) => {
            const s: SubMenu = new SubMenu(value as HTMLUListElement);
            s.init();
        });
    }
}
